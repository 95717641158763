import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLang } from "../LangContext";

const WhyUs = () => {
    const lang = useLang();

    const [whyUs, setWhyUs] = useState({});
    useEffect(() => {
        fetch('/api/why-us').then(res => res.json()).then(data => setWhyUs(data));
    }, []);

    return (
        <>
            <section className="page-header">
                <div className="page-header-bg" style={{ backgroundImage: 'url(../uploads/page-header-bg.jpg)' }}>
                </div>
                <div className="container">
                    <div className="page-header__inner">
                        <ul className="thm-breadcrumb list-unstyled">
                            <li><Link to="/">{lang.home}</Link></li>
                            <li><span>/</span></li>
                            <li>{lang.why_us}</li>
                        </ul>
                        <h2>{lang.why_us}</h2>
                    </div>
                </div>
            </section>

            <section className="healthy-food-one" id="why-us">
                <div className="healthy-food-one__bg float-bob-x" style={{ backgroundImage: 'url(../uploads/healthy-food-one-shape-1.png)' }} />
                <div className="container">
                    {whyUs && (
                        <div className="row">
                            <div className="col-xl-5">
                                <div className="healthy-food-one__left">
                                    <div className="healthy-food-one__img">
                                        <img src={whyUs.image} alt="food1" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-7">
                                <div className="healthy-food-one__right">
                                    <div className="section-title text-right">
                                        <h2 className="section-title__title">{lang.why_us}</h2>
                                        <div className="section-title__icon">
                                            <img src="../uploads/section-title-icon-1.png" alt="section-title-icon" />
                                        </div>
                                    </div>
                                    <p className="healthy-food-one__text">
                                        <div dangerouslySetInnerHTML={{ __html: whyUs[`description_${lang.lang}`] }}></div>
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </section>

        </>
    );
}

export default WhyUs;