import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLang } from "../LangContext";

const OurVision = () => {
    const lang = useLang();

    const [ourVision, setOurVision] = useState({});
    useEffect(() => {
        fetch('/api/our-vision').then(res => res.json()).then(data => setOurVision(data));
    }, []);

    return (
        <>
            <section className="page-header">
                <div className="page-header-bg" style={{ backgroundImage: 'url(../uploads/page-header-bg.jpg)' }}>
                </div>
                <div className="container">
                    <div className="page-header__inner">
                        <ul className="thm-breadcrumb list-unstyled">
                            <li><Link to="/">{lang.home}</Link></li>
                            <li><span>/</span></li>
                            <li>{lang.our_vision}</li>
                        </ul>
                        <h2>{lang.our_vision}</h2>
                    </div>
                </div>
            </section>

            <section className="Provide-One" id="our-vision">
                {ourVision && (
                    <div className="Provide-One__wrap">
                        <div className="Provide-One__left">
                            <div className="Provide-One__bg" style={{ backgroundImage: `url( ${ourVision.image}  )` }} />
                            <div className="provide-one__sopport">
                                <div className="provide-one__support-icon">
                                    <span className="icon-harvester" />
                                </div>
                                <p className="provide-one__support-text">{lang.for_better_future}</p>
                            </div>
                        </div>
                        <div className="Provide-One__right">
                            <div className="provide-one__bg-shape float-bob-x">
                                <img src="../uploads/provide-one-shape-1.png" alt="shap1" />
                            </div>
                            <div className="Provide-One__content">
                                <div className="section-title text-left">
                                    <h2 className="section-title__title">{lang.our_vision}</h2>
                                    <div className="section-title__icon">
                                        <img src="../uploads/section-title-icon-1.png" alt="shap2" />
                                    </div>
                                </div>
                                <p className="Provide-One__text">
                                    <div dangerouslySetInnerHTML={{ __html: ourVision[`description_${lang.lang}`] }}></div>
                                </p>
                            </div>
                        </div>
                    </div>
                )}
            </section>

        </>
    );
}

export default OurVision;