import React from 'react';
import { createBrowserRouter } from "react-router-dom";
import Error from './Error';
import App from '../App';
import Home from '../Pages/Home';
import Contact from '../Pages/Contact';
import Products from '../Pages/Products';
import Details from '../Pages/Details';
import WhyUs from '../Pages/WhyUs';
import OurVision from '../Pages/OurVision';
import About from '../Pages/About';
import Activities from '../Pages/Activities';
import Activity from '../Pages/Activity';

const Routes = createBrowserRouter([
    {
        path: "/",
        element: <App><Home /></App>,
        errorElement: <Error />,
    },
    {
        path: "/about",
        element: <App><About /></App>,
        errorElement: <Error />,
    },
    {
        path: "/contact-us",
        element: <App><Contact /></App>,
        errorElement: <Error />,
    },
    {
        path: "/why-us",
        element: <App><WhyUs /></App>,
        errorElement: <Error />,
    },
    {
        path: "/our-vision",
        element: <App><OurVision /></App>,
        errorElement: <Error />,
    },
    {
        path: "/products",
        element: <App><Products /></App>,
        errorElement: <Error />,
    },
    {
        path: "/details/:id",
        element: <App><Details /></App>,
        errorElement: <Error />,
    },
    {
        path: "/activities",
        element: <App><Activities /></App>,
        errorElement: <Error />,
    },
    {
        path: "/activity/:id",
        element: <App><Activity /></App>,
        errorElement: <Error />,
    },
]);

export default Routes;