import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLang } from "../LangContext";

const Products = () => {
    const lang = useLang();

    const [products, setProducts] = useState([]);
    useEffect(() => {
        fetch('/api/all-products').then(res => res.json()).then(data => setProducts(data));
    }, []);

    const gotoTop = () => {
        window.scrollTo(0, 0);
    }

    return (
        <>
            <section className="page-header">
                <div className="page-header-bg" style={{ backgroundImage: 'url(../uploads/page-header-bg.jpg)' }}>
                </div>
                <div className="container">
                    <div className="page-header__inner">
                        <ul className="thm-breadcrumb list-unstyled">
                            <li><Link to="/">{lang.home}</Link></li>
                            <li><span>/</span></li>
                            <li>{lang.products}</li>
                        </ul>
                        <h2>{lang.products}</h2>
                    </div>
                </div>
            </section>
            <section className="services-page">
                <div className="container">
                    <div className="row">
                        {products
                            .filter(product => product[`title_${lang.lang}`])
                            .map(product => (
                                <Link to={`/details/${product.id}`} className="col-xl-4 col-lg-4 mb-5 wow fadeInUp animated" data-wow-delay="100ms" onClick={gotoTop}>
                                    <div className="services-two__single">
                                        <div className="services-two__img mb-4">
                                            <img src={`../../${product.image}`} alt={product.title_en} />
                                        </div>
                                        <div className="services-two__hover-content">
                                            <h3 className="services-two__hover-title">
                                                <Link to={`/details/${product.id}`}>
                                                    {product[`title_${lang.lang}`]}
                                                </Link>
                                            </h3>
                                            <p className="services-two__hover-text">
                                                <td>{product[`description_${lang.lang}`].length > 100 ? <div dangerouslySetInnerHTML={{ __html: product[`description_${lang.lang}`].slice(0, 100) + '...' }}></div> : <div dangerouslySetInnerHTML={{ __html: product[`description_${lang.lang}`] }}></div>}</td>
                                            </p>
                                            <div className="services-two__hover-more-details">
                                                <Link to={`/details/${product.id}`}><i className="icon-left-arrow" />{lang.more_info}</Link>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                    </div>
                </div>
            </section>

        </>
    );
}

export default Products;