import { Link } from "react-router-dom";

const Error = () => {
    return (
        <section className="error-page">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="error-page__inner">
                            <div className="error-page__title-box">
                                <h2 className="error-page__title">404</h2>
                            </div>
                            <h3 className="error-page__tagline">Sorry, We Can't Find that Page!</h3>
                            <p className="error-page__text">The page you are looking for was moved, removed, renamed or never existed.</p>
                            <Link to="/" className="fw-bold h1">
                                <span className="text-success">Best</span><span className="text-warning">Feed</span><span>IQ</span>
                            </Link>
                            <div className="error-page__btn-box">
                                <a href="/" className="thm-btn error-page__btn">Back to Home<i className="icon-right-arrow" /> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
}

export default Error;