import React, { createContext, useContext } from 'react';

const LangContext = createContext();

export const useLang = () => {
  return useContext(LangContext);
};

export const LangProvider = ({ value, children }) => {
  return (
    <LangContext.Provider value={value}>
      {children}
    </LangContext.Provider>
  );
};
