import { useEffect, useState } from "react";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";
import en from "./locales/en.json";
import ar from "./locales/ar.json";
import krd from "./locales/krd.json";
import { LangProvider } from './LangContext';

function App({ children }) {
  const [data, setData] = useState({
    general: {
      phone_no: "",
      email: "",
      address_en: "",
      description_en: "",
    },
    social: [],
    lang: en, // Default language
  });

  const fetchGeneralData = async () => {
    try {
      const res = await fetch("/api/general");
      const general = await res.json();
      setData((prevState) => ({ ...prevState, general }));
    } catch (error) {
      console.error("Error fetching general data:", error);
    }
  };

  const fetchSocialData = async () => {
    try {
      const res = await fetch("/api/social");
      const social = await res.json();
      setData((prevState) => ({ ...prevState, social }));
    } catch (error) {
      console.error("Error fetching social data:", error);
    }
  };

  const fetchLangData = async () => {
    try {
      const res = await fetch("/api/lang");
      const langCode = await res.json();
      handleLanguageChange(langCode);
    } catch (error) {
      console.error("Error fetching language data:", error);
    }
  };

  const handleLanguageChange = (langCode) => {
    let lang;
    switch (langCode) {
      case "en":
        lang = en;
        break;
      case "ar":
        lang = ar;
        break;
      case "krd":
        lang = krd;
        break;
      default:
        lang = en; // Default to English if language code is not recognized
    }
    setData((prevState) => ({ ...prevState, lang }));
  };

  useEffect(() => {
    fetchGeneralData();
    fetchSocialData();
    fetchLangData(); // Fetch the initial language
  }, []);

  return (
    <LangProvider value={data.lang}>
      <main className="page-wrapper">
        <Header data={data.general} social={data.social} onLanguageChange={handleLanguageChange} lang={data.lang} />
        {children}
        <Footer data={data.general} social={data.social} lang={data.lang} />
      </main>
    </LangProvider>
  );
}

export default App;
