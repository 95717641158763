import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLang } from "../LangContext";

const About = () => {
    const lang = useLang();
    const [about, setAbout] = useState({});
    useEffect(() => {
        fetch('/api/about').then(res => res.json()).then(data => setAbout(data));
    }, []);

    return (
        <>
            <section className="page-header">
                <div className="page-header-bg" style={{ backgroundImage: 'url(../uploads/page-header-bg.jpg)' }}>
                </div>
                <div className="container">
                    <div className="page-header__inner">
                        <ul className="thm-breadcrumb list-unstyled">
                            <li><Link to="/">{lang.home}</Link></li>
                            <li><span>/</span></li>
                            <li>{lang.about}</li>
                        </ul>
                        <h2>{lang.about}</h2>
                    </div>
                </div>
            </section>

            <section className="about-one" id="about">
                <div className="about-one-shape-1 float-bob-x">
                    <img src="../uploads/about-one-shape-1.png" alt="shape-1" />
                </div>
                <div className="container">
                    {about && (
                        <div className="row">
                            <div className="col-xl-6">
                                <div className="about-one__left">
                                    <div className="section-title text-left">
                                        <span className="section-title__tagline">
                                            {about[`small_title_${lang.lang}`]}
                                        </span>
                                        <h2 className="section-title__title">
                                            {about[`large_title_${lang.lang}`]}
                                        </h2>
                                        <div className="section-title__icon">
                                            <img src="../uploads/section-title-icon-1.png" alt="title-icon" />
                                        </div>
                                    </div>
                                    <div dangerouslySetInnerHTML={{ __html: about[`description_${lang.lang}`] }}></div>
                                    <div className="about-one__btn-and-ceo">
                                        <div className="about-one__ceo">
                                            <div className="about-one__ceo-content">
                                                <h4 className="about-one__ceo-name">{about.signature}</h4>
                                                <p className="about-one__ceo-title">
                                                    {about[`signatured_by_${lang.lang}`]}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="about-one__right">
                                    <div className="about-one__img-box wow slideInRight" data-wow-delay="100ms" data-wow-duration="2500ms">
                                        <div className="about-one__img-one">
                                            <img src={about.background} alt={about.large_title_en} />
                                        </div>
                                        <div className="about-one__img-two">
                                            <img src={about.image} alt={about.large_title_en} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </section>

        </>
    );
}

export default About;