import $ from 'jquery';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLang } from "../LangContext";

const Home = () => {
    const lang = useLang();

    const thmSwiperInit = () => {
        $(".thm-swiper__slider").each(function () {
            const elm = $(this);
            const options = elm.data('swiper-options');
            new window.Swiper(elm[0], options);
        });
    }

    const [slides, setSlides] = useState([]);
    const [about, setAbout] = useState({});
    const [products, setProducts] = useState([]);
    const [whyUs, setWhyUs] = useState({});
    const [team, setTeam] = useState([]);
    const [ourVision, setOurVision] = useState({});
    const [clients, setClients] = useState([]);
    useEffect(() => {
        fetch('/api/slides').then(res => res.json()).then(data => setSlides(data));
        fetch('/api/about').then(res => res.json()).then(data => setAbout(data));
        fetch('/api/limit-products').then(res => res.json()).then(data => setProducts(data));
        fetch('/api/why-us').then(res => res.json()).then(data => setWhyUs(data));
        fetch('/api/limit-team').then(res => res.json()).then(data => setTeam(data));
        fetch('/api/our-vision').then(res => res.json()).then(data => setOurVision(data));
        fetch('/api/limit-clients').then(res => res.json()).then(data => setClients(data));
    }, []);

    useEffect(() => {
        if (slides.length) thmSwiperInit();
        if (products.length) {
            window.$('.product-carousel').owlCarousel({
                loop: true,
                margin: 30,
                nav: false,
                dots: true,
                autoplay: true,
                smartSpeed: 700,
                autoplayTimeout: 3000,
                responsive: {
                    0: {
                        items: 1
                    },
                    480: {
                        items: 2
                    },
                    768: {
                        items: 3
                    },
                    1200: {
                        items: 4
                    }
                }
            });
        }
        if (team.length) {
            window.$('.team-carousel').owlCarousel({
                loop: true,
                margin: 30,
                nav: false,
                dots: true,
                autoplay: true,
                smartSpeed: 700,
                autoplayTimeout: 2000,
                responsive: {
                    0: {
                        items: 1
                    },
                    480: {
                        items: 2
                    },
                    768: {
                        items: 3
                    },
                    1200: {
                        items: 4
                    }
                }
            });
        }
        if (clients.length) {
            window.$('.brand-one__carousel').owlCarousel({
                loop: true,
                margin: 30,
                nav: false,
                dots: false,
                autoplay: true,
                smartSpeed: 700,
                autoplayTimeout: 4000,
                responsive: {
                    0: {
                        items: 2
                    },
                    480: {
                        items: 3
                    },
                    768: {
                        items: 4
                    },
                    1200: {
                        items: 5
                    }
                }
            });

        }
    }, [slides, products, team, clients]);

    const gotoTop = () => {
        window.scrollTo(0, 0);
    }

    return (
        <>
            <section className="main-slider clearfix" id="home">
                <div className="swiper-container thm-swiper__slider" data-swiper-options={JSON.stringify({
                    slidesPerView: 1,
                    loop: true,
                    effect: 'fade',
                    pagination: {
                        el: '#main-slider-pagination',
                        type: 'bullets',
                        clickable: true
                    },
                    navigation: {
                        nextEl: '#main-slider__swiper-button-next',
                        prevEl: '#main-slider__swiper-button-prev'
                    },
                    autoplay: {
                        delay: 5000
                    }
                })}>
                    <div className="swiper-wrapper">
                        {slides
                            .filter(slide => slide[`title_${lang.lang}`])
                            .map((slide, index) => (
                                <div className="swiper-slide" key={index}>
                                    <div className="image-layer" style={{ backgroundImage: `url(${slide.image})` }} />
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="main-slider__content">
                                                    <p className="main-slider__sub-title">{slide[`shorts_${lang.lang}`]}</p>
                                                    <h2 className="main-slider__title">{slide[`title_${lang.lang}`]}</h2>
                                                    <div className="main-slider__btn-box">
                                                        <Link to="/products" className="thm-btn main-slider__btn">
                                                            {lang.learn_more} <i className="icon-left-arrow" />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                    <div className="swiper-pagination" id="main-slider-pagination" />
                    <div className="main-slider__nav">
                        <div className="swiper-button-prev" id="main-slider__swiper-button-next">
                            <i className="icon-right-arrow" />
                        </div>
                        <div className="swiper-button-next" id="main-slider__swiper-button-prev">
                            <i className="icon-right-arrow" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="feature-one">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4">
                            <div className="feature-one__single">
                                <div className="feature-one__icon">
                                    <span className="icon-farm" />
                                </div>
                                <div className="feature-one__content">
                                    <h3 className="feature-one__title">{lang.best_quality}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4">
                            <div className="feature-one__single">
                                <div className="feature-one__icon">
                                    <span className="icon-agriculture" />
                                </div>
                                <div className="feature-one__content">
                                    <h3 className="feature-one__title">{lang.best_price}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4">
                            <div className="feature-one__single">
                                <div className="feature-one__icon">
                                    <span className="icon-harvest" />
                                </div>
                                <div className="feature-one__content">
                                    <h3 className="feature-one__title">{lang.best_service}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about-one" id="about">
                <div className="about-one-shape-1 float-bob-x">
                    <img src="../uploads/about-one-shape-1.png" alt="shape-1" />
                </div>
                <div className="container">
                    {about && (
                        <div className="row">
                            <div className="col-xl-6">
                                <div className="about-one__left wow slideInLeft" data-wow-delay="100ms" data-wow-duration="1500ms">
                                    <div className="section-title text-left">
                                        <span className="section-title__tagline"> {about[`small_title_${lang.lang}`]}</span>
                                        <h2 className="section-title__title">{about[`large_title_${lang.lang}`]}</h2>
                                        <div className="section-title__icon">
                                            <img src="../uploads/section-title-icon-1.png" alt="title-icon" />
                                        </div>
                                    </div>
                                    <div dangerouslySetInnerHTML={{ __html: about[`description_${lang.lang}`] }}></div>
                                    <div className="about-one__btn-and-ceo">
                                        <div className="about-one__ceo">
                                            <div className="about-one__ceo-content">
                                                <h4 className="about-one__ceo-name">{about.signature}</h4>
                                                <p className="about-one__ceo-title">{about[`signatured_by_${lang.lang}`]}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="about-one__right">
                                    <div className="about-one__img-box wow slideInRight" data-wow-delay="100ms" data-wow-duration="1500ms">
                                        <div className="about-one__img-one">
                                            <img src={about.background} alt={about.large_title_en} />
                                        </div>
                                        <div className="about-one__img-two">
                                            <img src={about.image} alt={about.large_title_en} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </section>

            <section className="project-one" id="products">
                <div className="project-one__bg float-bob-y-2">
                </div>
                <div className="container">
                    <div className="section-title text-center">
                        <span className="section-title__tagline">{lang.we_prdouce_best_quality}</span>
                        <h2 className="section-title__title">{lang.products}</h2>
                        <div className="section-title__icon">
                            <img src="../uploads/section-title-icon-1.png" alt="icon1" />
                        </div>
                    </div>
                    <div className="container">
                        <div className="product-carousel thm-owl__carousel owl-theme owl-carousel carousel-dot-style">
                            {products && products
                                .filter(product => product[`title_${lang.lang}`])
                                .map((product, index) => ( 
                                    <Link to={`/details/${product.id}`} className="item" key={index} onClick={gotoTop}>
                                        <div className="project-one__single">
                                            <div className="project-one__inner">
                                                <div className="project-one__img">
                                                    <img src={product.image} alt={product[`title_${lang.lang}`]} />
                                                </div>
                                                <div className="project-one__arrow">
                                                    <Link to={`/details/${product.id}`}><i className="icon-right-arrow" /></Link>
                                                </div>
                                                <div className="project-one__content">
                                                    <span className="project-one__tagline">{lang.feed}</span>
                                                    <h3 className="project-one__title"><Link to={`/details/${product.id}`}>{product[`title_${lang.lang}`]}</Link></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                ))}
                        </div>
                    </div>
                </div>
            </section>

            <section className="healthy-food-one" id="why-us">
                <div className="healthy-food-one__bg float-bob-x" style={{ backgroundImage: 'url(../uploads/healthy-food-one-shape-1.png)' }} />
                <div className="container">
                    {whyUs && (
                        <div className="row">
                            <div className="col-xl-5">
                                <div className="healthy-food-one__left wow slideInLeft" data-wow-delay="100ms" data-wow-duration="1500ms">
                                    <div className="healthy-food-one__img">
                                        <img src={whyUs.image} alt="food1" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-7">
                                <div className="healthy-food-one__right wow slideInRight" data-wow-delay="100ms" data-wow-duration="1500ms">
                                    <div className="section-title text-right">
                                        <h2 className="section-title__title">{lang.why_us}</h2>
                                        <div className="section-title__icon">
                                            <img src="../uploads/section-title-icon-1.png" alt="section-title-icon" />
                                        </div>
                                    </div>
                                    <p className="healthy-food-one__text">
                                        <div dangerouslySetInnerHTML={{ __html: whyUs[`description_${lang.lang}`] }}></div>
                                    </p>
                                    <ul className="list-unstyled healthy-food-one__list">
                                        <li className="healthy-food-one__single">
                                            <div className="healthy-food-one__content">
                                                <div className="healthy-food-one__icon">
                                                    <span className="icon-harvester" />
                                                </div>
                                                <p className="healthy-food-one__title">{lang.production}</p>
                                            </div>
                                        </li>
                                        <li className="healthy-food-one__single">
                                            <div className="healthy-food-one__content">
                                                <div className="healthy-food-one__icon">
                                                    <span className="icon-agriculture-1" />
                                                </div>
                                                <p className="healthy-food-one__title">{lang.growth}</p>
                                            </div>
                                        </li>
                                        <li className="healthy-food-one__single">
                                            <div className="healthy-food-one__content">
                                                <div className="healthy-food-one__icon">
                                                    <span className="icon-harvest-1" />
                                                </div>
                                                <p className="healthy-food-one__title">{lang.presentation}</p>
                                            </div>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </section>

            <section className="unbeatable-one">
                <div className="unbeatable-one__bg  jarallax" data-jarallax data-speed="0.2" data-imgposition="50% 0%" style={{ backgroundImage: 'url(../uploads/unbeatable-one-bg.jpg)' }} />
                <div className="container">
                    <div className="unbeatable-one__inner text-center">
                        <div className="unbeatable-one__content">
                            <div className="unbeatable-one__shape-one wow slideInLeft" data-wow-delay="100ms" data-wow-duration="1500ms">
                                <img src="../uploads/unbeatable-shape-1.png" alt="shap1" className="float-bob-y" />
                            </div>
                            <div className="unbeatable-one__shape-two wow slideInRight" data-wow-delay="100ms" data-wow-duration="1500ms">
                                <img src="../uploads/unbeatable-shape-2.png" alt="shap2" className="float-bob-y" />
                            </div>
                            <p className="unbeatable-one__tagline">{lang.best_feed}</p>
                            <h3 className="unbeatable-one__title">{lang.the_best}<br /> {lang.by_german}</h3>
                            <div className="unbeatable-one__btn-box">
                                <Link to="/products" className="thm-btn unbeatable-one__btn">{lang.more_info}<i className="icon-left-arrow" /> </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="team-carousel-page" id="team">
                <div className="container">
                    <div className="team-carousel thm-owl__carousel owl-theme owl-carousel carousel-dot-style">
                        {team
                            .filter(item => item[`full_name_${lang.lang}`])
                            .map((item, index) => (
                                <div className="item" key={index}>
                                    <div className="team-one__single">
                                        <div className="team-one__image">
                                            <img src={item.image} alt={item[`full_name_${lang.lang}`]} />
                                        </div>
                                        <div className="team-one__content">
                                            <h3 className="team-one__name"><a href="/">{item[`full_name_${lang.lang}`]}</a></h3>
                                            <p className="team-one__designation">{item[`job_title_${lang.lang}`]}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </section>

            <section className="Provide-One" id="our-vision">
                {ourVision && (
                    <div className="Provide-One__wrap">
                        <div className="Provide-One__left wow slideInLeft" data-wow-delay="100ms" data-wow-duration="1500ms">
                            <div className="Provide-One__bg" style={{ backgroundImage: `url( ${ourVision.image}  )` }} />
                            <div className="provide-one__sopport">
                                <div className="provide-one__support-icon">
                                    <span className="icon-harvester" />
                                </div>
                                <p className="provide-one__support-text">{lang.for_better_future}</p>
                            </div>
                        </div>
                        <div className="Provide-One__right wow slideInRight" data-wow-delay="100ms" data-wow-duration="1500ms">
                            <div className="provide-one__bg-shape float-bob-x">
                                <img src="../uploads/provide-one-shape-1.png" alt="shap1" />
                            </div>
                            <div className="Provide-One__content">
                                <div className="section-title text-left">
                                    <h2 className="section-title__title">{lang.our_vision}</h2>
                                    <div className="section-title__icon">
                                        <img src="../uploads/section-title-icon-1.png" alt="shap2" />
                                    </div>
                                </div>
                                <p className="Provide-One__text">
                                    <div dangerouslySetInnerHTML={{ __html: ourVision[`description_${lang.lang}`] }}></div>
                                </p>
                            </div>
                        </div>
                    </div>
                )}
            </section>

            <section className="counter-one" id="client">
                <div className="counter-one__bg" style={{ backgroundImage: 'url(../uploads/counter-one-shape-3.png)' }}>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="counter-one__inner">
                                <div className="brand-one__carousel thm-owl__carousel owl-theme owl-carousel">
                                    {clients
                                        .filter(item => item[`title_${lang.lang}`])
                                        .map((item, index) => (
                                            <div className="item" key={index}>
                                                <div className="team-one__single">
                                                    <div className="team-one__img-box">
                                                        <div className="team-one__img">
                                                            <img src={item.logo} alt={item[`title_${lang.lang}`]} />
                                                        </div>
                                                    </div>
                                                    <div className="team-one__content">
                                                        <h3 className="team-one__title"><a href="#">{item[`title_${lang.lang}`]}</a></h3>
                                                        <p className="team-one__subtitle">{item[`address_${lang.lang}`]}</p>
                                                        <p className="team-one__subtitle">{item.phone}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="cta-one">
                <div className="cta-one__bg" data-jarallax data-speed="0.2" data-imgposition="50% 0%" style={{ backgroundImage: 'url(../uploads/cta-one-bg.jpg)' }} />
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="cta-one__inner">
                                <div className="cta-one__left">
                                    <div className="cta-one__icon">
                                        <span className="icon-agriculture-2" />
                                    </div>
                                    <h3 className="cta-one__title">
                                        {lang.capacity}<br /> {lang.annually}
                                    </h3>
                                </div>
                                <div className="cta-one__right">
                                    <Link to="/contact-us" className="thm-btn cta-one__btn">{lang.contact_us}<i className="icon-left-arrow" /> </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

export default Home;
