import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useLang } from "../LangContext";

const Details = () => {
    const lang = useLang();
    const id = useParams().id;
    const [product, setProduct] = useState({});
    const [products, setProducts] = useState([]);
    const [social, setSocial] = useState([]);
    const [productImage, setProductImage] = useState([]);

    useEffect(() => {
        fetch(`/api/details/${id}`).then(res => res.json()).then(data => setProduct(data));
        fetch('/api/limit-products').then(res => res.json()).then(data => setProducts(data));
        fetch('/api/social').then(res => res.json()).then(data => setSocial(data))
        fetch(`/api/product-images/${id}`).then(res => res.json()).then(data => setProductImage(data));
    }, [id]);

    const gotoTop = () => {
        window.scrollTo(0, 0);
    }

    return (
        <>
            <section className="page-header">
                <div className="page-header-bg" style={{ backgroundImage: 'url(../uploads/page-header-bg.jpg)' }}>
                </div>
                <div className="container">
                    <div className="page-header__inner">
                        <ul className="thm-breadcrumb list-unstyled">
                            <li><Link to="/">{lang.home}</Link></li>
                            <li><span>/</span></li>
                            <li><Link to="/products">{lang.products}</Link></li>
                        </ul>
                        <h2>{lang.product_details}</h2>
                    </div>
                </div>
            </section>

            <section className="project-details">
                <div className="container">
                    <div className="project-details__content">
                        {product && (
                            <div className="row">
                                <div className="col-xl-8 col-lg-8">
                                    <div className="project-details__content-left">
                                        <h3 className="project-details__title-1">
                                            {product[`title_${lang.lang}`]}
                                        </h3>
                                        <p className="project-details__text-1">
                                            <div dangerouslySetInnerHTML={{ __html: product[`description_${lang.lang}`] }}></div>
                                        </p>
                                    </div>
                                    <div className="row mb-3 mt-2">
                                        {productImage && productImage.map((image, x) => (
                                            <div class="col-md-6" key={x}>
                                                <div class="product__all-single">
                                                    <div class="product__all-img-box">
                                                        <div class="product__all-img">
                                                            <img src={`../../${image.image}`} alt="besfeediq" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="project-details__details-box mt-3">
                                        <div className="project-details__bg-shape" style={{ backgroundImage: 'url(../uploads/project-details-shape-1.png)' }}>
                                        </div>
                                        <div className="project-details__social">
                                            {social && social.map((item, index) => (
                                                <a key={index} href={item.social_link}>
                                                    <div dangerouslySetInnerHTML={{ __html: item.social_icon }}></div>
                                                </a>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4">
                                    <div className="project-details__content-right">
                                        <div className="mb-5 py-2">
                                            <img src={`../../${product.image}`} alt={product.title_en} style={{ "maxWidth": "520px" }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section >

            <section className="Smilar-project">
                <div className="container">
                    <div className="section-title text-center">
                        <span className="section-title__tagline">{lang.smilar_products}</span>
                        <h2 className="section-title__title">{lang.products}</h2>
                        <div className="section-title__icon">
                            <img src="../../uploads/section-title-icon-1.png" alt="section-title-icon" />
                        </div>
                    </div>
                    <div className="row">
                        {products && products.map((product, index) => (
                            <div className="col-xl-3 col-lg-6 col-md-6 mb-5 mt-3 wow fadeInUp" data-wow-delay={`${index * 100}ms`} key={index} onClick={gotoTop}>
                                <div className="project-one__single">
                                    <div className="project-one__inner">
                                        <div className="project-one__img">
                                            <img src={`../../${product.image}`} alt={product[`title_${lang.lang}`]} />
                                        </div>
                                        <div className="project-one__arrow">
                                            <Link to={`/details/${product.id}`}><i className="icon-right-arrow" /></Link>
                                        </div>
                                        <div className="project-one__content">
                                            <span className="project-one__tagline">{lang.feed}</span>
                                            <h3 className="project-one__title"><Link to={`/details/${product.id}`}>{product[`title_${lang.lang}`]}</Link></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>



        </>
    );
}

export default Details;