import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLang } from "../LangContext";

const Activities = () => {
    const lang = useLang();
    const [activity, setActivity] = useState([]);
    useEffect(() => {
        fetch('/api/all-activity').then(res => res.json()).then(data => setActivity(data));
    }, []);

    const gotoTop = () => {
        window.scrollTo(0, 0);
    }
    return (
        <>
            <section className="page-header">
                <div className="page-header-bg" style={{ backgroundImage: 'url(../uploads/page-header-bg.jpg)' }}>
                </div>
                <div className="container">
                    <div className="page-header__inner">
                        <ul className="thm-breadcrumb list-unstyled">
                            <li><Link to="/">{lang.home}</Link></li>
                            <li><span>/</span></li>
                            <li>{lang.activity}</li>
                        </ul>
                        <h2>{lang.activity}</h2>
                    </div>
                </div>
            </section>
            <section className="project-page-one">
                <div className="container">
                    <div className="row">
                        {activity
                            .filter(activity => activity[`title_${lang.lang}`])
                            .map(activity => (
                                <Link to={`/details/${activity.id}`} className="col-xl-4 col-lg-6 col-md-6 wow fadeInLeft" data-wow-delay="100ms" onClick={gotoTop}>
                                    <div class="project-one__single">
                                        <div class="project-one__inner">
                                            <div class="project-one__img">
                                                <img src={`../../${activity.image}`} alt={activity.title_en} />
                                            </div>
                                            <div class="project-one__arrow">
                                                <Link to={`/activity/${activity.id}`}><i class="icon-left-arrow"></i></Link>
                                            </div>
                                            <div class="project-one__content">
                                                <span class="project-one__tagline">{new Date(activity.created).toLocaleDateString()}</span>
                                                <h3 class="project-one__title">
                                                    <Link to={`/activity/${activity.id}`}>
                                                        {activity[`title_${lang.lang}`]}
                                                    </Link>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                    </div>
                </div>
            </section>

        </>
    );
}

export default Activities;